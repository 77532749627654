import Script from 'next/script'

const getAnalyticsTag = () => {
  return {
    __html: `
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?c9d71970880cb332c146019c0b656f97";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();`,
  }
}

export const AnalyticsScript: React.FC = () => {
  return (
    <Script
      id="baiduanalyticstag"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={getAnalyticsTag()}
    />
  )
}
