import React, { useEffect, useState } from 'react'
import { AnalyticsScript } from '~/components/business/AnalyticsScript'
import { VConsoleScript } from '~/components/business/VconsoleScript'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { Dialog } from 'antd-mobile'
import { LoginModal } from '~/components/business/LoginModal'
import { useStores } from '~/hooks/useStores'
import { WechatGuide } from '~/components/ui/WechatGuide'
import { isWeixin } from '~/utils'

const isProd = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

export const Layout = observer(
  ({ children }: { children: React.ReactNode }) => {
    const router = useRouter()
    const { store } = useStores()
    const [guideVisible, setGuideVisible] = useState(false)
    useEffect(() => {
      /** 默认Dialog不会在路由切换时，清空DOM */
      return () => {
        Dialog.clear()
      }
    }, [router.asPath])

    useEffect(() => {
      if (isWeixin() && !['/artwork/[id]'].includes(router.pathname)) {
        setGuideVisible(true)
      }
    }, [])

    return (
      <>
        {children}
        {store.modal?.login && <LoginModal />}
        <WechatGuide visible={guideVisible} setVisible={setGuideVisible} />
        {isProd && <AnalyticsScript />}
        {!isProd && <VConsoleScript />}
      </>
    )
  }
)
