import { gql } from '@apollo/client'

export const GET_MOBILE_CODE = gql`
  mutation getMobileCode(
    $areaCode: String = "+86"
    $behaviorParam: BehaviorVerifyParam
    $mobile: String!
    $scene: VerifyCodeScene = LOGIN_REGISTER
    $verifyType: MobileVerifyType = SMS
  ) {
    status: sendMobileVerifyCode(
      param: {
        areaCode: $areaCode
        mobile: $mobile
        scene: $scene
        behaviorParam: $behaviorParam
        verifyType: $verifyType
      }
    )
  }
`

export const LOGIN_BY_MOBILE = gql`
  mutation loginByMobile(
    $areaCode: String = "+86"
    $mobile: String!
    $code: String!
    $inviteCode: String
    $thirdUid: Long
    $thirdAccountType: ThirdAccountType
  ) {
    data: loginByMobile(
      param: {
        areaCode: $areaCode
        mobile: $mobile
        code: $code
        inviteCode: $inviteCode
        thirdUid: $thirdUid
        thirdAccountType: $thirdAccountType
        sourceSite: SHEN_YIN
      }
    ) {
      isNew
      token
      tokenVoucher
    }
  }
`

export const LOGOUT = gql`
  mutation logout {
    status: logout
  }
`
