import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

const HOST = process.env.NEXT_PUBLIC_HOST

export const SEOHead: React.FC<{
  title?: string | number | null
  desc?: string | null
}> = ({ title, desc }) => {
  const router = useRouter()

  const isHome = router.pathname === '/'

  return (
    <Head>
      <title>{isHome || !title ? '神隐：幻世绘影' : `${title} | 神隐`}</title>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
      {desc && <meta name="description" content={desc} />}
      <link rel="canonical" href={HOST + router.asPath} />
    </Head>
  )
}
