import React from 'react'
import { IMAGE_CDN } from '~/configs'

export const CDNImage: React.FC<{
  src: string
  alt?: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}> = ({ src, alt = '', className, style, onClick }) => {
  const isProd = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

  return (
    <img
      className={className}
      style={style}
      src={isProd ? IMAGE_CDN + src : src}
      alt={alt}
      onClick={onClick}
    />
  )
}
