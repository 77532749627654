import React from 'react'

export const WechatGuide: React.FC<{
  visible?: boolean
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ visible = false, setVisible }) => {
  return (
    <div>
      {visible && (
        <div
          className="fixed top-0 z-[1000] w-screen h-screen bg-neutral-900/80"
          // onClick={() => setVisible?.(false)}
        >
          <img
            src={'/images/common/guide.png'}
            className="block mt-9 mr-8 float-right w-[175px]"
            alt=""
          />
        </div>
      )}
    </div>
  )
}
