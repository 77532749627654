import { Dialog } from 'antd-mobile'
import Link from 'next/link'

export const usePrivacy = () => {
  const privacyDialog = (onOk: () => void) => {
    Dialog.show({
      bodyClassName: `feature-dialog`,
      title: '用户服务协议及隐私政策',
      content: (
        <>
          为保证你的合法权益，请阅读并同意《
          <Link href="/us/user-agreement">用户协议</Link>
          》和《
          <Link href="/us/privacy">隐私政策</Link>》
        </>
      ),
      closeOnAction: true,
      actions: [
        [
          {
            key: 'confirm',
            text: '同意并登录',
            className: 'dialog-btn--primary',
            onClick: () => {
              onOk()
            },
          },
          {
            key: 'close',
            text: '不同意',
            className: 'dialog-btn--default',
          },
        ],
      ],
    })
  }
  return {
    privacyDialog,
  }
}
