import { debounce, DebounceSettings } from 'lodash'
import { useCallback, DependencyList } from 'react'

/** 函数时组件的防抖 */
/**
 *
 * @param fn 防抖函数
 * @param DependencyList useCallback依赖
 * @param wait 防抖函数时间间隔
 * @options {
 *  使用场景：
 *  1.希望第一次立即调用，防抖结束后不再调用  （leading：true，trailing:false);
 *  2.希望第一次不调用，防抖结束后立即调用一次（leading：false，trailing: true)
 *  @leading 指定在延迟开始前调用，默认false
 *  @trailing 指定在延迟结束后调用，默认true
 *
 * }
 * @returns useCallback-memorized函数
 */
export const useDebounce = (
  fn: any,
  deps: DependencyList = [],
  wait: number | undefined = 1000,
  options: DebounceSettings = {
    leading: true,
    trailing: false,
  }
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(fn, wait, options), deps)
}
