import { useMutation } from '@apollo/client'
import { Checkbox, Dialog, Form, Input, Toast } from 'antd-mobile'
import { useState } from 'react'
import {
  GET_AVATAR_PAGE,
  GET_MOBILE_CODE,
  LOGIN_BY_MOBILE,
} from '~/graphqls/gqls'
import {
  AvatarServiceType,
  getAvatarPage,
  getAvatarPageVariables,
  getMobileCode,
  getMobileCodeVariables,
  loginByMobile,
  loginByMobileVariables,
} from '~/graphqls/types'
import { useDebounce } from '~/hooks/useDebounce'
import { usePrivacy } from '~/hooks/usePrivacy'
import { getGraphData, graphQLErrorMessage, isMobileReg } from '~/utils'
import { Captcha, IValidInfoProps } from './Captcha'
import Cookies from 'js-cookie'
import Link from 'next/link'
import { FeatureBtn } from '../ui/FeatureBtn'
import classNames from 'classnames'
import { useStores } from '~/hooks/useStores'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { useImperativeQuery } from '~/hooks/useImperativeQuery'
import { CDNImage } from '~/components/ui/CDNImage'

export const LoginForm = observer(() => {
  /** 是否勾选隐私协议 */
  const [isPrivacyCheck, setPrivacyCheck] = useState(false)

  const router = useRouter()
  const [form] = Form.useForm()

  const { privacyDialog } = usePrivacy()

  const { store } = useStores()

  const avatarPageAction = useImperativeQuery<
    getAvatarPage,
    getAvatarPageVariables
  >(GET_AVATAR_PAGE)

  const [getMobileCodeAction] = useMutation<
    getMobileCode,
    getMobileCodeVariables
  >(GET_MOBILE_CODE)

  const [loginByMobileAction] = useMutation<
    loginByMobile,
    loginByMobileVariables
  >(LOGIN_BY_MOBILE)

  /** 发送验证码前校验 */
  const beforeSendCode = async (next: any) => {
    await form.validateFields(['mobile'])
    if (typeof next === 'function') {
      next()
    }
  }

  /** 获取手机验证码 */
  const onMobileCode = useDebounce((validInfo: IValidInfoProps) => {
    const mobile = form.getFieldValue('mobile')
    /** 获取验证码接口获取 */
    getMobileCodeAction({
      variables: {
        mobile: mobile,
        behaviorParam: {
          token: validInfo.token,
          authenticate: validInfo.authenticate,
        },
      },
    })
      .then(() => {
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: '获取验证码成功',
        })
      })
      .catch(error => {
        Toast.clear()
        Toast.show({
          icon: 'fail',
          content: graphQLErrorMessage(error) || '获取验证码失败',
        })
      })
  })

  /** 回退动作 */
  const backAction = (token: string) => {
    Cookies.set('gate-token', token, {
      expires: 365,
    })

    store.setToken(token)
    store.modal?.setLoginVisible(false)

    avatarPageAction({
      input: {
        serviceType: AvatarServiceType.SINGLE_IMAGE,
      },
    }).then(res => {
      const avatarList = getGraphData(
        res.data.avatarModuleQuery?.pageAvatar?.edges || []
      )
      const nextUrl = !!avatarList.length ? '/artwork/create' : '/avatar/create'
      router.push(nextUrl)
    })
  }

  const onLogin = (formData: any) => {
    /** 验证码登录/注册 */
    loginByMobileAction({
      variables: {
        mobile: formData.mobile,
        code: formData.code,
      },
    })
      .then(data => {
        Toast.show({
          icon: 'success',
          content: data?.data?.data?.isNew === false ? '登录成功' : '注册成功',
          afterClose: () => {
            backAction(data?.data?.data?.token || '')
          },
        })
      })
      .catch(error => {
        Toast.show({
          icon: 'fail',
          content: graphQLErrorMessage(error) || '登录/注册失败',
        })
      })
  }

  /** 表单提交 */
  const onSubmit = useDebounce(async () => {
    const formData = await form.validateFields()
    if (isPrivacyCheck) {
      onLogin(formData)
    } else {
      privacyDialog(() => {
        setPrivacyCheck(true)
        onLogin(formData)
      })
    }
  }, [onLogin, isPrivacyCheck])

  return (
    <>
      <Form
        className={classNames('feature-form')}
        layout="horizontal"
        form={form}
      >
        <Form.Item
          name="mobile"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern: isMobileReg,
              message: '请输入正确的手机号',
            },
          ]}
          extra={
            <Captcha
              className="pl-4"
              beforeStart={beforeSendCode}
              onSuccess={onMobileCode}
            ></Captcha>
          }
        >
          <Input placeholder="请输入手机号" clearable />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[{ required: true, message: '请输入手机验证码' }]}
        >
          {/* 备注：添加maxLength，解决IOS短信验证码自动填充两次的BUG */}
          <Input placeholder="请输入验证码" maxLength={6} clearable />
        </Form.Item>

        <div className="text-[12px] text-[#a88984] mb-4">
          <Checkbox
            checked={isPrivacyCheck}
            onChange={val => setPrivacyCheck(val)}
          >
            我已阅读并同意
            <Link href="/us/user-agreement">「用户协议」</Link>和
            <Link href="/us/privacy">「隐私政策」</Link>
          </Checkbox>
        </div>
        <FeatureBtn size="sm" onClick={onSubmit}>
          登录
        </FeatureBtn>
        <div className="text-[#8F6006] text-xs flex items-center justify-center mt-5">
          <CDNImage className="w-4 h-4 mr-2" src="/images/common/gift.png" />
          首次登录即可免费生成3次写真
        </div>
      </Form>
    </>
  )
})

export const LoginModal = observer(() => {
  const { store } = useStores()
  return (
    <Dialog
      bodyClassName="feature-dialog"
      visible={store.modal?.login}
      closeOnMaskClick={true}
      onClose={() => store.modal?.setLoginVisible(false)}
      title="登录注册"
      content={<LoginForm />}
    ></Dialog>
  )
})
