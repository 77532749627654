import { gql } from '@apollo/client'

/** 当前用户信息 */
export const GET_CURRENT_USERINFO = gql`
  query getCurrentUserInfo {
    userinfo: copyrightUserInfo {
      avatarUrl
      nickname
      base {
        uid
        mobile
      }
      integralWalletBalance {
        availableBalance
      }
    }
  }
`
/** 获取可用永久积分 */
export const GET_AVAILABLE_BALANCE = gql`
  query getAvailableBalance {
    availableBalance: copyrightUserInfo {
      integralWalletBalance {
        availableBalance
      }
    }
  }
`
