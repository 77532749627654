import { gql } from '@apollo/client'

export const GET_AVATAR_PAGE = gql`
  query getAvatarPage(
    $input: AvatarQueryInput
    $first: Int = 20
    $offset: Int = 0
  ) {
    avatarModuleQuery {
      pageAvatar(input: $input, page: { first: $first, offset: $offset }) {
        edges {
          node {
            id
            cover
            status
            isDefaultTemplate
          }
        }
      }
    }
  }
`
/** 新建化身 */
export const CREATE_AVATAR = gql`
  mutation createAvatar($input: AvatarCreateInput) {
    avatarModuleMutation {
      createAvatar(input: $input) {
        id
      }
    }
  }
`

/** 我的化身轮询 */
export const POLL_AI_AVATAR_LIST = gql`
  query pollAiAvatarList($ids: [Long]) {
    avatarModuleQuery {
      listAvatar(idList: $ids) {
        id
        cover
        status
        isDefaultTemplate
      }
    }
  }
`

/** 化身训练图片检测 */
export const IMAGE_CHECK = gql`
  mutation imageCheck($input: ImageCheckInput) {
    trainImageMutation {
      imageCheck(input: $input) {
        imageDetectionCheck
        imageDetectionId
        imageFaceCheck
      }
    }
  }
`

/** 化身积分消耗记录 */
export const AVATAR_BILL_List = gql`
  query avatarBillList($first: Int = 20, $offset: Int = 0) {
    avatarModuleQuery {
      avatarBill(page: { first: $first, offset: $offset }) {
        edges {
          node {
            change
            event
            eventTime
            billId
          }
        }
        pageInfo {
          hasNextPage
          totalCount
        }
      }
    }
  }
`
