import { OperationVariables, useApolloClient } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useCallback } from 'react'

export function useImperativeQuery<
  TData = any,
  TVariables = OperationVariables,
  THeader = Record<string, any>
>(query: DocumentNode) {
  const client = useApolloClient()

  const imperativelyCallQuery = useCallback(
    (queryVariables?: TVariables, headers?: THeader) => {
      return client.query<TData>({
        query,
        variables: queryVariables as OperationVariables,
        fetchPolicy: 'no-cache',
        context: {
          ...headers,
        },
      })
    },
    [client, query]
  )

  return imperativelyCallQuery
}
