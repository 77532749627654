import React, { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { useMount } from 'react-use'
import classNames from 'classnames'

/** 验证码正确结果 */
export interface IValidInfoProps {
  token: string
  authenticate: string
}
/** 发送验证码props */
interface ISendCodeProps {
  onSuccess: (validInfo: IValidInfoProps) => void
  beforeStart: (next: () => void) => void
  captchaId?: string
  value?: number
  className?: string
}

export const Captcha: React.FC<ISendCodeProps> = ({
  onSuccess,
  beforeStart,
  captchaId = 'get-captcha',
  value = 60,
  className = undefined,
}) => {
  const [isCountStart, setIsCountStart] = useState(false)
  const [counter, setCounter] = useState(value)
  useMount(() => {
    require('~/plugins/yunpian')
    new YpRiddler({
      expired: 10,
      mode: 'dialog',
      noButton: true,
      winWidth: '80%',
      lang: 'zh-cn',
      container: document.getElementById(captchaId),
      appId: '0e790ca2c37d4b3c9aaab7ac0032ea06',
      version: 'v1',
      onError: function (param: { code: number }) {
        Toast.clear()
        if (!param.code) {
          Toast.show({
            icon: 'fail',
            content: '错误请求',
          })
        } else if (Math.floor(param.code / 100) === 5) {
          // 服务不可用时，开发者可采取替代方案
          Toast.show({
            icon: 'fail',
            content: '验证服务暂不可用',
          })
        } else if (param.code === 429) {
          Toast.show({
            icon: 'fail',
            content: '请求过于频繁，请稍后再试',
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: '验证失败，请重试',
          })
        }
      },
      onSuccess: function (validInfo: IValidInfoProps, close: () => void) {
        onSuccess(validInfo)
        setIsCountStart(true)
        close()
      },
      onFail: function (code: number, msg: string, retry: () => void) {
        retry()
      },
      beforeStart: function (next: () => void) {
        beforeStart(next)
      },
    })
  })
  useEffect(() => {
    let timer: ReturnType<typeof setInterval>
    if (isCountStart) {
      timer = setInterval(() => {
        if (counter > 1) {
          setCounter(counter => counter - 1)
        } else {
          setIsCountStart(false)
          setCounter(value)
          clearInterval(timer)
        }
      }, 1000)
    }

    return () => {
      clearInterval(timer)
    }
  }, [isCountStart, counter, value])

  return (
    <div
      id={captchaId}
      className={classNames(className, 'hover:text-primary cursor-pointer')}
    >
      {isCountStart ? (
        <span className="text-[12px] text-[#fdecb6]">{counter}s</span>
      ) : (
        <span className="text-[12px] text-[#fdecb6]">获取验证码</span>
      )}
    </div>
  )
}
