import { gql } from '@apollo/client'

/** 我的写真 */
export const GET_ARTWORK_LIST = gql`
  query getArtworkList(
    $first: Int
    $offset: Int
    $param: AvatarArtworkQueryInput
  ) {
    avatarArtworkQuery {
      avatarArtworkPage(
        page: { first: $first, offset: $offset }
        param: $param
      ) {
        edges {
          node {
            id
            status
            artworkUrl
            violation
            width
            height
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

/** 轮训作品信息 */
export const POLL_ARTWORK_LIST = gql`
  query pollArtworkList($artworkIds: [Long!]) {
    avatarArtworkQuery {
      avatarArtworkList(artworkIds: $artworkIds) {
        id
        status
        artworkUrl
        violation
        width
        height
      }
    }
  }
`

/** 图片详情 */
export const GET_ARTWORK_DETAILS = gql`
  query getArtworkDetails($id: Long!) {
    avatarArtworkQuery {
      avatarArtwork(id: $id) {
        id
        status
        artworkUrl
        violation
        width
        height
        user {
          uid
        }
        template {
          id
        }
        avatar {
          id
        }
      }
    }
  }
`
/** 写真记录 */
export const GET_ARTWORK_BILLS = gql`
  query getArtworkBills($first: Int, $offset: Int) {
    avatarArtworkQuery {
      avatarArtworkBill(page: { first: $first, offset: $offset }) {
        edges {
          node {
            billId
            change
            event
            eventTime
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`
/** 删除图片 */
export const DELETE_AVATAR_ARTWORK = gql`
  mutation deleteAvatarArtworkMutation($ids: [Long!]!) {
    avatarArtworkMutation {
      deleteAvatarArtwork(ids: $ids)
    }
  }
`

/** 获取写真模版 */
export const GET_ARTWORK_CREATE_MENU = gql`
  query getArtworkCreateMenu($param: CreateMenuQueryInput) {
    avatarArtworkQuery {
      artworkCreateMenu(param: $param) {
        avatarId
        templateOptions {
          templateId
          name
          icon
          theme
          new
        }
      }
    }
  }
`

/** 生成写真 */
export const CREATE_AVATAR_ARTWORK = gql`
  mutation createAvatarArtwork($param: CreateAvatarArtworkParam!) {
    avatarArtworkMutation {
      id: createAvatarArtwork(param: $param)
    }
  }
`
