import { gql } from '@apollo/client'

export const QINIU_TOKEN = gql`
  query qiniuToken($fileName: String, $bucket: OssBucket, $dirName: String) {
    upToken: ossUploadToken(
      param: { bucket: $bucket, fileName: $fileName, dirName: $dirName }
    ) {
      region
      token
    }
  }
`

export const REPORT_PV = gql`
  mutation reportPV($param: ReportPageViewInput!) {
    status: reportPageView(param: $param)
  }
`

/** 浏览统计 */
export const REPORT_VIEW = gql`
  mutation reportView($input: GeneralSocialActionInput!) {
    squareModule {
      status: doViewAction(param: $input)
    }
  }
`

export const IMAGE_DETECTION = gql`
  mutation imageDetection($input: ImageDetectionInput) {
    commonMutation {
      id: imageDetection(input: $input)
    }
  }
`
