import classNames from 'classnames'
import { toCDNImage } from '~/utils'

export const FeatureBtn: React.FC<{
  type?: 'primary' | 'default'
  size?: 'md' | 'sm' | 'xs'
  children: React.ReactNode
  onClick?: () => void
  className?: string
  needFontFamily?: boolean
}> = ({
  type = 'primary',
  size = 'md',
  onClick,
  children,
  className,
  needFontFamily = true,
}) => {
  const url =
    size === 'xs'
      ? `/images/common/btn-small-${type}-bg@2x.png`
      : `/images/common/btn-${type}-bg@2x.png`
  return (
    <div
      className={classNames(
        'flex items-center justify-center text-[#fdecb6] mx-auto',
        {
          'h-[55px] w-[326px] text-[24px]': size === 'md',
          'h-[48px] w-[284px] text-[20px]': size === 'sm',
          'h-[28px] w-[101px] text-[12px]': size === 'xs',
        },
        className
      )}
      style={{
        background: `url(${toCDNImage(`${url}`)}) center center / cover`,
      }}
      onClick={onClick}
    >
      <div
        className={classNames('-mt-1 flex items-center justify-center', {
          ['font-song']: needFontFamily,
        })}
      >
        {children}
      </div>
    </div>
  )
}
